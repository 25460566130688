<template>
    <div class="page">
        <Aside></Aside>
        <div class="content">
            <div class="content-box">
                <div class="content-header">个人信息中心</div>
                <div class="content-detail">
                    <a-form-model ref="form" label-width="108px" size="small">
                        <div class="form-top">
                            <a-form-model-item label="头像" class="avatar-box">
                                <div class="img-box" :style="{'background-image':`url(${userInfo ? userInfo.avatar : ''})`}"></div>
                                <a-button class="btn" @click="showModal">更改头像</a-button>
                            </a-form-model-item>
                            <a-form-item label="账号" class="account-label">
                                <div>{{userInfo ? userInfo.account : ''}}</div>
                            </a-form-item>
                        </div>
                        <div class="form-bottom">
                            <a-form-item label="当前团队" class="account-label">
                                <div class="txt overflow">{{userInfo ? platformData.platform_name : ''}}</div>
                            </a-form-item>
                            <a-form-item label="个人姓名" class="account-label">
                                <div class="txt overflow">{{userInfo ? userInfo.username : ''}}</div>
                            </a-form-item>
                        </div>
                    </a-form-model>
                </div>
            </div>
        </div>
        <a-modal :destroyOnClose="true" v-model:visible="visible" title="更换新头像" :width="508" :bodyStyle="bodyStyle" @cancel="cancel" @ok="saveAvatar" :confirm-loading="confirmLoading">
            <div class="tailoring-content">
                <div class="preview-box-parcel">
                    <div class="title">头像预览</div>
                    <div class="before" v-if="imgUrl"></div>
                    <div v-else>
                        <img src="@/assets/images/small_avatar.png" />
                    </div>
                </div>
                <div class="container">
                    <div class="title">编辑图片</div>
                    <div class="img-container" v-if="imgUrl">
                        <img :src="imgUrl" ref="image" alt=""> 
                    </div>
                    <div class="noImg-box big" v-else>
                        <img src="@/assets/images/avater_default.png" />
                    </div>
                </div>
            </div>
            <div class="button-box">
                <div>
                    <a-button type="primary" size="small" ghost @click="resetCrop">复位</a-button>
                    <a-button type="primary" style='margin:0 16px;' size="small" ghost @click="imgUrl && cropper.rotate(45)">旋转</a-button>
                    <a-button type="primary" size="small" ghost @click="scaleCrop">转向</a-button>
                </div>
                <div>
                    <a-upload
                        class="elupload"
                        ref="upload"
                        :before-upload="handleBeforeUpload"
                        :accept="accept"
                        action="">
                        <a-button type="primary" size="small">上传本地图片</a-button>
                    </a-upload>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import Aside from "./components/aside";
import {setUser} from "@/api/data.js";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
    components:{Aside},
    data(){
        return {
            accept:'.jpg,.jpeg,.png',
            visible:false,
            cropper:'',
            bodyStyle:{
                padding:'0 24px 21px 24px',
            },
            imgUrl:'',
            confirmLoading:false,
            flagY: false //裁剪的图片是否反转，默认false(不反转)
        }
    },
    computed:{
        userInfo(){
            return this.$store.state.userinfo;
        },
        platformData(){
            return this.$store.state.platformData;
        }
    },
    methods:{
        showModal(){
            this.visible = true;
            this.flagY = false;
            this.imgUrl = '';
        },
        // 重置
        resetCrop(){
            if(this.imgUrl){
                this.flagY = false;
                this.cropper.reset();
            }
        },
        // 反转
        scaleCrop() {
            if(this.imgUrl){
                if (this.flagY) {
                    this.cropper.scaleY(1), (this.flagY = false);
                } else {
                    this.cropper.scaleY(-1), (this.flagY = true);
                }
            }
        },
        init(){
            this.cropper = new Cropper(this.$refs.image, {
                viewMode: 1,
                dragMode: 'none',
                initialAspectRatio: 1,
                aspectRatio: 1,
                preview: '.before',
                autoCropArea: 0.9,
                zoomOnWheel: false,
            })
        },
        handleBeforeUpload(file){
            let acceptList = ["png","jpeg","jpg"];
            let fileType = file.name.split('.').pop().toLowerCase();
            if (acceptList.indexOf(fileType) === -1) {
                this.$message.warning('只支持上传jpg、Png、Jpeg格式');
                return false
            }
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                this.imgUrl = e.target.result;
                this.flagY = false;
                if(this.cropper){ 
                    this.cropper.replace(this.imgUrl); 
                }else{
                    this.$nextTick(() => {
                        this.init();
                    })
                }
            }
            return false // 阻止Upload的默认上传
        },
        saveAvatar(){
            if(this.imgUrl){
                this.confirmLoading = true;
                this.cropper.getCroppedCanvas().toBlob((blob) => {
                    const formData = new FormData();
                    formData.append("avatar", blob);
                    formData.append("username", this.userInfo.username)
                    formData.append("sex", this.userInfo.sex);
                    setUser(formData).then(data => {
                        this.confirmLoading = false;
                        this.visible = false;
                        this.cancel();
                        if(data.code===1){
                            this.$message.success('编辑成功');
                            this.$store.commit('getUserInfo');
                        }
                    }).catch(() => {
                        this.confirmLoading = false;
                        this.visible = false;
                        this.cancel();
                    })
                })
            }else{
                this.visible = false;
            }
        },
        cancel(){
            //销毁裁剪对象
            this.cropper.destroy()
            this.cropper = null
            this.visible = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.content-detail{
    ::v-deep .ant-form-item{
        display: flex;
        .ant-form-item-label{
            padding:0;
            color:#515151;
            width:108px;
        }
        .ant-form-item-control-wrapper{
            margin-left:80px !important;
        }
    } 
    .form-bottom{
        padding-top:30px;
        border-top:1px solid #F3F3F3;
    }
    .txt{
        width:260px;
        font-size: 14px;
        line-height: 34px;
        height:34px;
        background: #F7F9FA;
        border-radius: 4px;
        color:#515151;
        padding-left:20px;
    }
}
.avatar-box{
    ::v-deep .ant-form-item-children{
        display:flex;
        align-items: center;
        .elupload{
            margin-left: 30px;
        }
        .ant-upload-list{
            display:none;
        }
    }
    .img-box{
        width:60px;
        height:60px;
        border-radius: 50%;
        background-size: cover;
    }
    img{
        border: 0;
        outline: 0;
    }
    .btn{
        margin-left:10px;
    }
}
.tailoring-content{
  display: flex;
  .title{
      font-size:14px;
      line-height: 56px;
      color:#8C8C8C;
      margin-bottom:4px;
  }
}
.preview-box-parcel{
    width:140px;
}
.before{
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100%;
}
.img-container{
    width: 320px;
    height: 260px;
    overflow: hidden;
}
.noImg-box{
    width: 320px;
    height: 260px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-box{
    margin-left:140px;
    margin-top:20px;
    display: flex;
    justify-content: space-between;
}
::v-deep .ant-upload-list{
    display: none !important;
}
</style>